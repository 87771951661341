import { AlertType } from './../../../components/posts-errors';
import { Component, Input, OnInit, OnDestroy, OnChanges, EventEmitter, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, } from '@angular/forms';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { MatDrawer } from '@angular/material';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import moment from 'moment';

import { PostService } from 'src/app/services/post.service';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from './../../../services/modal.service';
import { StorageImageService } from './../../../services/storage-image.service';
import { IMediaUrl } from './../../../constants/media-url';
import { DatesService } from 'src/app/services/dates.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';

export interface IDataForLastStep {
  postType?: any;
  cadence?: any;
  timeOfPublish?: any;
  timeOfUnpublish?: any;
  schedulePublish?: any;
  scheduledDetele?: any;
  timeOfDetele?: any,
  startDate?: any;
  endDate?: any;
  startTime?: any;
  endTime?: any;
  couponCode?: any;
  linkToRedeem?: any;
  termsAndCondition?: any;
  publishesEvery?: any;
  endsOn?: any;
  firstPublish?: any;
  repeatsOn?: any;
  dailyDate?: any;
}

@Component({
  selector: 'app-post-manegement-slider',
  templateUrl: './post-manegement-slider.component.html',
  styleUrls: ['./post-manegement-slider.component.scss']
})
export class PostManegementSliderComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('inputUpload', {static: false}) inputUpload: FormInputUploadComponent;
  
  @Input() drawer: MatDrawer ;
  @Input() isSliderOpened = false;
  @Input() postToEdit: any = {};
  @Input() isBulk = true;
  @Input() location = {};
  
  @Output() closeSlider = new EventEmitter();

  public isEdit = false;
  public form: FormGroup;
  public step = 0;
  public accountsSelected: any = [];
  public locationsSelected: any = [];
  public isDraft = false;
  public cancelText = {
    title: 'You have unsaved changes',
    message: 'If you close and leave this view, you will lose any unsaved changes.'
  }
  public stepTitle = [
    '1. Select Locations',
    '2. Add Post Details',
    '3. When to Publish',
    '4. Confirmation'
  ];
  public hoursList = [];
  public daysList = [];
  public readonly nameOfDaysOptions = [
    { value: [0], displayName: 'Sunday' },
    { value: [1], displayName: 'Monday' },
    { value: [2], displayName: 'Tuesday' },
    { value: [3], displayName: 'Wednesday' },
    { value: [4], displayName: 'Thursday' },
    { value: [5], displayName: 'Friday' },
    { value: [6], displayName: 'Saturday' }
  ]
  public imageSelectedUrl: string = null;
  public imageRequirements = {
    type: ['image/png', 'image/jpg'],
    min_width: 400,
    min_height: 300,
    min_size: 10240,
    max_size: 5000000
  };
  public firestorRef: AngularFireStorageReference;
  public isLoading = false;
  public actionsUrl = [];
  public actionOptions = [
    {value: 'NONE', displayName: 'None'},
    {value: 'BOOK', displayName: 'Book'},
    {value: 'ORDER', displayName: 'Order online'},
    {value: 'SHOP', displayName: 'Buy'},
    {value: 'LEARN_MORE', displayName: 'Learn More'},
    {value: 'SIGN_UP', displayName: 'Sign Up'},
    {value: 'CALL', displayName: 'Call Now'}
  ];
  public dataForLastStep: IDataForLastStep = {};
  public mediaUrlArray: IMediaUrl[] = [];
  public selectedUrl = '';
  public selectedFileIndex = 0;
  public swiperConfig: any = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true
  };
  public hoursListOptions: Observable<string[]>;

  public dataIsBeingVerified = false;
  public isCorrectData = false;
  public fieldsWithError = [];
  public schedulePreviewData = [];
  private _isUpdating = false;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _dateS: DatesService,
    private _postS: PostService,
    private _sessionS: SessionService,
    private _accountS: AccountService,
    private _storageImgService: StorageImageService,
    private _modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
    this._subscribeSafe(this._storageImgService.multipleMediaUrl$, // multiple media subscription
     (urlsArray) => {
          if (urlsArray && urlsArray.length > 0) {
              this.mediaUrlArray = [...urlsArray].filter(url => !url.error);
          } else {
              this.mediaUrlArray = [];
          }
          // this.isUploading = false;
      },(e)=>{
          console.error(e);
          this._modalService.openErrorModal(
              'Upload Error',
              "Error uploading the image, try again"
          )
          // this.isUploading = false;
      });
  }

  initData(): void {
    // const currentDate = new Date();
    // const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    this.hoursList  = this._dateS.loadHoursDropdown(true, true);
    this.hoursListOptions = this.form?.get('eventTimeStart')!?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.daysList = [
      { value: 1, displayName: 'First day of the month' },
      { value: 15, displayName: '15th day of the month' },
      { value: -1, displayName: 'Last day of the month' },
      ...this._postS.generateOrdinalDays(2, 28)
    ];
    this.isDraft = this.isEdit && this.postToEdit?.status === 'DRAFT';
    this.initForm();
  }

  initForm(): void {
    //first Step
    if (this.isEdit) {
      const accounts = this._accountS.buildToggleLocationAccounts(this.postToEdit?.locations);
      this.postToEdit.accounts = accounts
      this.accountsSelected = accounts;

      if(this.postToEdit?.post?.media?.length > 0) {
        this.postToEdit?.post?.media.forEach(el => {
          this.mediaUrlArray.push({
            url: el?.sourceUrl,
            category: el?.mediaFormat
          })
        });
        this._storageImgService.setMultipleMediaUrl(this.mediaUrlArray);
      }
    }

    if (!this.isBulk) {
      this.accountsSelected = this.location;
    }

    this.form = new FormGroup({
      postType: new FormControl(this.postToEdit?.post?.topicType || null, [Validators.required]),
      cadence: new FormControl(this.postToEdit?.scheduleRules?.type || null, [Validators.required]),
      schedulePublish: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? 'publish_immediately' : 'custom'
      ),
      postStartDate: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? null : this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date)
      ),
      repeatsOnMonthly: new FormControl(
        this.postToEdit?.scheduleRules?.type != 'MONTHLY' || !this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth ? null :
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.includes(1) || this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(15) || this.postToEdit.scheduleRules.publishRule.daysOfMonth?.includes(-1) ? 
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.[0] : 'custom'
      ),
      repeatsOnDay: new FormControl(
        this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth ? 
        this.daysList?.find(el => el?.value == this.postToEdit?.scheduleRules?.publishRule?.daysOfMonth?.[0])?.value : 
        null
      ),
      repeatsOnWeekly: new FormControl(
        this.postToEdit?.scheduleRules?.type == 'WEEKLY' && this.postToEdit?.scheduleRules?.publishRule?.daysOfWeek ? 
        this.nameOfDaysOptions?.find(el => el.value == this.postToEdit.scheduleRules.publishRule.daysOfWeek?.[0])?.value : []
      ),
      publishDay: new FormControl(this.postToEdit?.scheduleRules?.type == 'DAILY' ? this.postToEdit?.scheduleRules?.publishRule?.daysOfWeek : []),
      publishTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.publishRule?.time) || null),
      unpublishTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.unpublishRule?.time) || '11:59 PM'),
      matchScheduleDate: new FormControl(this.isEdit ? this.postToEdit?.scheduleRules?.userInput?.schedule?.matchEventDates : true),
      deleteTime: new FormControl(this._dateS.hours24To12(this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.time) || null),
      deleteDate: new FormControl(
        this.postToEdit?.scheduleRules?.type != "INSTANT" ? null : 
        !this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.date ? null : this.getFormDate(this.postToEdit.scheduleRules.userInput.schedule.end.date)
      ),
      endsOn: new FormControl(!Object.keys(this.postToEdit)?.length ? null :
        this.postToEdit?.scheduleRules?.interval ? 'custom_occurrences' :
        this.postToEdit?.scheduleRules?.endDate ? 'custom_date' :
        this.postToEdit?.scheduleRules?.userInput?.schedule?.type != "INSTANT" ? 'never' : null
      ),
      endsOnCustomDate: new FormControl(this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.date) || null),
      endsOnAfterOcurrences: new FormControl(this.postToEdit?.scheduleRules?.userInput?.schedule?.end?.limit_occurrences || null),
      firstPublish: new FormControl(
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_now ? "post_immediately" :
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.publish_next_occurrence ? "next_occurrence" :
        this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date ? "custom" : null
      ),
      firstPublishDate: new FormControl(this.getFormDate(this.postToEdit?.scheduleRules?.userInput?.firstPublish?.date) || null),
      title: new FormControl(this.postToEdit?.post?.title),
      summary: new FormControl(this.postToEdit?.post?.summary || null),
      actionType: new FormControl(this.postToEdit?.post?.callToAction?.actionType || 'NONE'),
      actionUrl: new FormControl(this.getActionUrl()),
      instantEventDatesStart: new FormControl(this.getFormDate(this.postToEdit?.post?.eventSchedule?.publishRule?.date) || null),
      instantEventDatesEnd: new FormControl(this.getFormDate(this.postToEdit?.post?.eventSchedule?.deleteRule?.date) || null),
      eventTimeStart: new FormControl(this._dateS.hours24To12(this.postToEdit?.post?.eventSchedule?.publishRule?.time)),
      eventTimeEnd: new FormControl(this._dateS.hours24To12(this.postToEdit?.post?.eventSchedule?.deleteRule?.time) || null),
      weeklyEventsDates: new FormControl(
        !this.postToEdit?.post ? null :
        this.postToEdit?.post?.eventSchedule?.publishRule?.matchScheduleDate && this.postToEdit?.post?.eventSchedule?.publishRule?.matchScheduleDate ? 
        'corresponds_publish_date' : 'custom'
      ),
      eventDatesStartWeekly: new FormControl(this.nameOfDaysOptions?.find(el => el.value?.includes(this.postToEdit?.scheduleRules?.userInput?.schedule?.publishRule?.daysOfWeek?.[0]))?.value || null),
      eventDatesEndWeekly: new FormControl(this.nameOfDaysOptions?.find(el => el.value?.includes(this.postToEdit?.post?.eventSchedule?.deleteRule?.daysOfWeek?.[0]))?.value || null),
      eventDatesStartMonthly: new FormControl(this.daysList?.find(el => el?.value == this.postToEdit?.scheduleRules?.userInput?.schedule?.publishRule?.daysOfMonth?.[0])?.value || 1),
      eventDatesEndMonthly: new FormControl(this.daysList?.find(el => el?.value == this.postToEdit?.post?.eventSchedule?.deleteRule?.daysOfMonth?.[0])?.value || -1),
      couponCode: new FormControl(this.postToEdit?.post?.offer?.couponCode || null),
      linkToRedeem: new FormControl(this.postToEdit?.post?.offer?.redeemOnlineUrl || null),
      termsAndCondition: new FormControl(this.postToEdit?.post?.offer?.termsConditions || null),
    });

    if (this.cadence?.value) {
      const cadenceSub = this.cadence.valueChanges.subscribe(() => {
        this.addDynamicValidations();
      });
      this._subscriptions.push(cadenceSub);
    }

    this.addDynamicValidations();
  }

  getActionUrl(): string {
    return (
      !this.postToEdit?.post?.callToAction?.url ? null :
      this.actionsUrl?.find(el => el.key == this.postToEdit?.post?.callToAction?.url)?.value ?
      this.actionsUrl?.find(el => el.key == this.postToEdit?.post?.callToAction?.url)?.value : 
      this.postToEdit.post.callToAction.url
    )
  }

  getActionLinks() {
    this.actionsUrl = [];
    if(!this.actionType?.value || this.actionType?.value === 'CALL' || this.actionType?.value === 'NONE') {
      this.actionUrl.setValue(null)
      return;
    }

    this.isLoading = true;
    const accounts = this.accountsSelected.forEach(acc => {
      acc?.locations.forEach(loc => loc.name = loc.locationName);
    });

    this._postS.getActionLinks(this.accountsSelected).subscribe(
      res => {
        if(res?.data) {
          this.actionsUrl = res?.data;

          if(this.isEdit) {
            this.actionUrl.setValue(this.getActionUrl())
          }
          
          this.isLoading = false;
        }
      },
      err => {
        this.isLoading = false;
      }
    )
  }

  clearActionUrl(): void {
    this.actionUrl.setValue(null)
  }

  validateInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', 'A', 'P', 'M', ':', 'BACKSPACE', ' ', 'TAB', 'ARROWLEFT', 'ARROWRIGHT', 'DELETE'];
    const key = event.key.toUpperCase();

    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  convertToUppercase(event: Event, field): void {
    const inputElement = event.target as HTMLInputElement;
    const uppercasedValue = inputElement.value.toUpperCase();
    
    inputElement.value = uppercasedValue;
    this.form.get(field)?.setValue(uppercasedValue, { emitEvent: false });
  }

  formatTime(event: Event, field: string): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.toUpperCase();
  
    // Remove invalid characters
    value = value.replace(/[^0-9APM:]/g, '');
  
    // Match the input against "hh:mm AM/PM" or "hh:mm" without AM/PM specified
    const match = value.match(/^(\d{1,2}):?(\d{0,2})\s?(AM|PM)?$/);
  
    if (match) {
      let hours = match[1].padStart(2, '0'); // Ensure hours have 2 digits
      let minutes = (match[2] || '00').padStart(2, '0'); // Ensure minutes have 2 digits
      let period = match[3] || ''; // May be empty or "AM"/"PM"
  
      // Limit hours to the range 0-23 (for initial input validation)
      hours = Math.min(parseInt(hours, 10), 23).toString().padStart(2, '0');
  
      // Limit minutes to the range 0-59
      minutes = Math.min(parseInt(minutes, 10), 59).toString().padStart(2, '0');
  
      // Convert 24-hour format to 12-hour format
      if (parseInt(hours, 10) >= 13) {
        if (parseInt(hours, 10) > 12) {
          // Subtract 12 to convert 24-hour to 12-hour
          hours = (parseInt(hours, 10) - 12).toString().padStart(2, '0');
        }
        period = 'PM';
      } else if (parseInt(hours, 10) === 0) {
        // Convert "00" to "12 AM"
        hours = '12';
        period = 'AM';
      } else if (!period) {
        // Default to "AM" if no period is provided
        period = 'AM';
      }
  
      // Rebuild the formatted value in "hh:mm AM/PM" format
      value = `${hours}:${minutes} ${period}`;
    } else {
      value = ''; // Clear the value if it doesn't match the expected format
    }
  
    inputElement.value = value;
    this.form.get(field)?.setValue(value, { emitEvent: false });
  }
  
  applyDynamicValidation(condition, targetControl, referenceControl, alwaysRequired, hasvalidateEndTimeSameDay, validateEndTimeSameDay): void {
    if (this._isUpdating) return;
    this._isUpdating = true;
  
    const newValidators = [
      alwaysRequired() || referenceControl ? Validators.required : null,
      hasvalidateEndTimeSameDay() ? validateEndTimeSameDay() : null
    ].filter(Boolean);

    if (targetControl) {
      targetControl.setValidators(!condition ? null : Validators.compose(newValidators));
      targetControl.updateValueAndValidity({ emitEvent: false });
    }
  
    this._isUpdating = false;
  }
  
  addDynamicValidations(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
    this._subscriptions = [];
  
    const validations = [
      { 
        condition: () => this.postType?.value !== 'STANDARD' && this.cadence?.value === 'INSTANT',
        fieldsToWatch: ['instantEventDatesStart', 'eventTimeStart', 'instantEventDatesEnd'],
        targetControl: this.eventTimeEnd,
        referenceControl: this.eventTimeStart,
        alwaysRequired: () => false,
        hasvalidateEndTimeSameDay: () => true,
        validateEndTimeSameDay: () => this._validateEndTimeSameDay('eventTimeStart', 'eventTimeEnd'),
      },
      { 
        condition: () => this.postType?.value !== 'STANDARD',
        fieldsToWatch: ['eventTimeStart', 'eventTimeEnd'],
        targetControl: this.eventTimeEnd,
        referenceControl: this.eventTimeStart,
        alwaysRequired: () => false,
        hasvalidateEndTimeSameDay: () => this.cadence?.value === 'DAILY',
        validateEndTimeSameDay: () => this._validateEndTimeSameDay('eventTimeStart', 'eventTimeEnd'),
      },      
      { 
        condition: () => this.step === 2 && this.cadence?.value === 'INSTANT',
        fieldsToWatch: ['deleteTime', 'publishTime', 'deleteDate', 'postStartDate'],
        targetControl: this.deleteTime,
        referenceControl: null,
        alwaysRequired: () => false,
        hasvalidateEndTimeSameDay: () => true,
        validateEndTimeSameDay: () => this._validateEndTimeSameDay('publishTime', 'deleteTime'),
      },
      {
        condition: () => this.postType?.value === 'STANDARD' || (this.step === 2 && this.cadence?.value !== 'INSTANT'),
        fieldsToWatch: ['postStartDate', 'firstPublishDate'],
        targetControl: this.publishTime,
        referenceControl: () => (this.postType?.value === 'STANDARD' ? this.postStartDate : null),
        alwaysRequired: () => this.step === 2 && this.cadence?.value !== 'INSTANT',
        hasvalidateEndTimeSameDay: () => false,
      },
      // { 
      //   condition: () => this.postType?.value === 'STANDARD',
      //   fieldsToWatch: ['postStartDate'],
      //   targetControl: this.publishTime,
      //   referenceControl: this.postStartDate,
      //   alwaysRequired: () => false,
      //   hasvalidateEndTimeSameDay: () => false,
      // },
      // { 
      //   condition: () => this.step === 2 && this.cadence?.value !== 'INSTANT',
      //   fieldsToWatch: ['firstPublishDate'],
      //   targetControl: this.publishTime,
      //   referenceControl: null,
      //   alwaysRequired: () => true,
      //   hasvalidateEndTimeSameDay: () => false,
      // },
    ];
  
    validations.forEach(({ condition, fieldsToWatch, targetControl, referenceControl, alwaysRequired, hasvalidateEndTimeSameDay, validateEndTimeSameDay }) => {
      fieldsToWatch.forEach((fieldToWatch) => {
        const control = this.form.get(fieldToWatch);
  
        if (control) {
          const sub = control.valueChanges.subscribe(() => {
            this.applyDynamicValidation(
              condition(),
              targetControl,
              referenceControl?.value,
              alwaysRequired,
              hasvalidateEndTimeSameDay,
              validateEndTimeSameDay
            );
          });
  
          this._subscriptions.push(sub);
        }
      });
    });
  }
  

  addValidationsStepTwo(): void {
    this.removeAllValidations();

    if (this.cadence?.value) {
      this.form.controls.summary.setValidators([Validators.required]);
      this.title?.setValidators(this.postType?.value !== 'STANDARD' ? [Validators.required] : null);
      if(this.cadence?.value == 'INSTANT') {
        if (this.postType?.value !== 'STANDARD') {
          this.form.controls.instantEventDatesEnd.setValidators([
            Validators.required,
            this._validateStartBeforeEnd('instantEventDatesStart', 'instantEventDatesEnd')
          ]);
          this.form.controls.instantEventDatesStart.setValidators([Validators.required]);
          this.form.controls.instantEventDatesEnd.markAsTouched();
        }
      } else {
        switch(this.cadence?.value) {
          case 'DAILY':
            if(this.postType?.value !== 'STANDARD') {
              this.setTimeHours();
              this.publishDay?.setValidators(Validators.required);
            } 
          break;
          case 'WEEKLY':
            if (this.postType?.value !== 'STANDARD') {
              this.eventDatesStartWeekly?.setValidators(Validators.required);
              this.eventDatesEndWeekly?.setValidators(Validators.required);
            }
            break;
          case 'MONTHLY':
            this.eventDatesStartMonthly?.setValidators(Validators.required);
            this.eventDatesEndMonthly?.setValidators(Validators.required);
            break;
        }
      }
      this.updateValueAndValidity();
    }
  }

  addFormFieldsStepThree(): void {
    this.removeAllValidations();

    if (this.cadence?.value !== 'INSTANT') {
      this.endsOn?.setValidators(Validators.required);
      this.endsOnCustomDate?.setValidators(this.isRequired(this.endsOn?.value, 'custom_date') ? [Validators.required] : null);
      this.endsOnAfterOcurrences?.setValidators(this.isRequired(this.endsOn?.value, 'custom_occurrences') ? [Validators.required] : null);
    } else {
      this.deleteDate?.setValidators([this._validateStartBeforeEnd('postStartDate', 'deleteDate')]);
    }

    if(this.postType?.value !== 'STANDARD') {
      
      if(!this.isEdit && this.matchScheduleDate?.value && this.cadence?.value !== 'INSTANT') {
        this.publishTime?.setValue(this.eventTimeStart?.value || '12:00 AM')
        
        switch(this.cadence?.value) {
          case 'WEEKLY':
            this.repeatsOnWeekly?.setValue(this.eventDatesStartWeekly?.value);
            break;
          case 'MONTHLY':
            this.repeatsOnDay?.setValue(this.eventDatesStartMonthly?.value);
            break;
        }
      }
    } else {
        this.publishDay?.setValidators(this.cadence?.value === 'DAILY' ? [Validators.required] : null);
        this.form.get('publishTime')?.setValidators(this.cadence?.value === 'DAILY' ? [Validators.required] : null);
        this.repeatsOnWeekly?.setValidators(this.cadence?.value === 'WEEKLY' ? [Validators.required] : null);
        this.repeatsOnDay?.setValidators(this.cadence?.value === 'MONTHLY' ? [Validators.required] : null);
    }
    this.updateValueAndValidity();
  }

  setTimeHours() {
    if (this.isEdit) { return }
    const start = this.cadence?.value === 'DAILY' ? '12:00 AM' : null;
    const end = this.cadence?.value === 'DAILY' ? '11:59 PM' : null;
    
    this.eventTimeStart.setValue(start);
    this.eventTimeEnd.setValue(end);
  }

  removeAllValidations(): void {
    const keys = Object.keys(this.form.controls);

    keys.forEach(k => {
      if(k != 'postType' && k != 'cadence')
      this.form.controls[k].clearValidators()
    });

    this.updateValueAndValidity();
  }

  updateValueAndValidity(): void {
    const keys = Object.keys(this.form.controls);

    keys.forEach(k => {
      this.form.controls[k].updateValueAndValidity()
    });
  }

  isRequired(field, value = null): boolean {
    return !value ? true : field === value;
  }

  selectDay(day: number): void {
    if (this.isEdit && !this.isDraft) { return }
    let values = this.publishDay?.value;
    let repeteadElement = this.publishDay.value.findIndex(el => el == day);
    
    if (repeteadElement > -1) {
      values.splice(repeteadElement, 1);
    } else {
      values.push(day);
    }
    
    values = values.sort((a, b) => a - b);
    this.publishDay.setValue(values);
  }

  parseTime(time): string {
    if(!time) { return null }
    const hours = time.slice(0, 2);
    const parsedTime = this._dateS.hours12To24(time);
    const hour = (parsedTime?.hours)?.toString()?.padStart(2, '0');
    const minutes = (parsedTime?.minutes)?.toString()?.padStart(2, '0');

    return `${hour}:${minutes}`;
  }

  getFormDate(date): string {
    return date ? `${date}T00:00:00` : null;
  }

  getServiceDate(date, isYMD = true): string {
    return (
      date && isYMD ?
      this._dateS.getStringDateYMD(moment(date)) :
      date && !isYMD ?
      this._dateS.getStringDateMDY(moment(date)) :
      null
    );
  }

  getUrlsMedia() {
    const urlsArray = [];

    this.mediaUrlArray.forEach(el => {
      const format = !el?.category?.includes('/') ? el?.category : el?.category.split('/')[0] === 'image' ? 'PHOTO' : 'VIDEO';
      
      urlsArray.push(
        {
          "mediaFormat": format, 
          "sourceUrl": el?.url
        }
      );
    })
  
    return urlsArray.length ? urlsArray : null;
  }

  buildServiceData(isDraft = false): any {
    this.accountsSelected.forEach(acc => {
      acc?.locations.forEach(loc => loc.name = loc.locationName);
    });

    return {
      "gid": this._sessionS.getSession().gid,
      "accounts": this.accountsSelected,
      "post": {
        "summary": this.summary.value,
        "callToAction": this.postType.value == 'OFFER' || (!this.actionType?.value || this.actionType?.value === 'NONE') ? null :
        {
          "actionType": this.actionType?.value,
          "url": !this.actionUrl?.value ? null : this.actionsUrl.find(el => el.value == this.actionUrl.value)?.key ? this.actionsUrl.find(el => el.value == this.actionUrl.value)?.key : this.actionUrl.value,
        },
        "title": this.postType.value !== 'STANDARD' ? this.title.value : null,
        "eventSchedule": this.postType.value == 'STANDARD' ? null : {
          "publishRule": {
            "daysOfWeek": (
              this.cadence.value == 'DAILY' ? this.publishDay.value :
              this.cadence.value == 'WEEKLY' ? this.eventDatesStartWeekly.value : null
            ),
            "daysOfMonth": this.cadence.value == 'MONTHLY' && this.eventDatesStartMonthly.value ? [this.eventDatesStartMonthly.value] : null,
            "time": this.eventTimeStart?.value ? this.parseTime(this.eventTimeStart.value) : this.parseTime("12:00 AM"),
            "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesStart.value) : null,
            "matchScheduleDate": this.cadence?.value === 'DAILY' ? true : this.cadence?.value !== 'INSTANT' ? this.matchScheduleDate?.value : false
          },
          "deleteRule": {
            "daysOfWeek": (
              this.cadence.value == 'DAILY' ? this.publishDay.value :
              this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null
            ),
            "daysOfMonth": (
              this.cadence.value == 'MONTHLY' && this.eventDatesEndMonthly.value ? [this.eventDatesEndMonthly.value] : null
            ),
            "time": this.eventTimeEnd?.value ? this.parseTime(this.eventTimeEnd.value) : this.parseTime("11:59 PM"),
            "date": this.cadence.value == 'INSTANT' ? this.getServiceDate(this.instantEventDatesEnd.value) : null,
            "matchScheduleDate": this.cadence?.value === 'DAILY' ? true : this.cadence?.value !== 'INSTANT' ? this.matchScheduleDate?.value : false
          }
        },
        "media": this.getUrlsMedia(),
        "topicType": this.postType?.value,
        "offer": this.postType.value !== 'OFFER' || (!this.form.controls.couponCode?.value && !this.form.controls.linkToRedeem?.value && !this.form.controls.termsAndCondition?.value) ? null :
        {
          "couponCode": this.form.controls.couponCode?.value,
          "redeemOnlineUrl": this.form.controls.linkToRedeem?.value,
          "termsConditions": this.form.controls.termsAndCondition?.value
        }
      },
      "status": isDraft ? "DRAFT" : "ACTIVE",
      "is_bulk": this.isBulk,
      "is_recurring": this.cadence.value != 'INSTANT' ? true : false,
      "is_scheduled": this.cadence.value != 'INSTANT' || (this.cadence.value == 'INSTANT' && this.postStartDate?.value) ? true : false,
      "first_publish": {
        "date": (
          this.cadence.value == 'INSTANT' && this.postStartDate?.value ? this.getServiceDate(this.postStartDate.value) : 
          this.firstPublishDate.value ? this.getServiceDate(this.firstPublishDate.value) : null
        ),
        "publish_now": (this.cadence?.value == 'INSTANT' && !this.postStartDate?.value) ? true : false,
        "publish_next_occurrence": this.cadence?.value !== 'INSTANT' && !this.firstPublishDate?.value ? true : false,
      },
      "schedule": {
        "type": this.cadence.value,
        "end":  this.endsOn.value != 'custom_date' && this.endsOn.value != 'custom_occurrences' && !this.deleteDate.value  ? null : {
          "date": (
            this.cadence.value == 'INSTANT' && this.deleteDate.value ? this.getServiceDate(this.deleteDate.value) :
            this.endsOn.value == 'custom_date' ? this.getServiceDate(this.endsOnCustomDate.value) : null
          ),
          "limit_occurrences": this.endsOn.value == 'custom_occurrences' ? this.endsOnAfterOcurrences.value : null,
          "time": this.deleteTime.value ? this.parseTime(this.deleteTime.value) : this.parseTime("11:59 PM")
        },
        "publishRule": this.cadence.value == 'INSTANT' && !this.postStartDate?.value ? null : {
          "daysOfWeek": (
            this.cadence.value == 'DAILY' ? this.publishDay.value :
            this.cadence.value == 'WEEKLY' ? this.repeatsOnWeekly.value : null
          ),
          "daysOfMonth": (
            this.cadence.value == 'MONTHLY' ? [this.repeatsOnDay?.value] : null
          ),
          "time": this.publishTime?.value ? this.parseTime(this.publishTime.value) : this.parseTime("12:00 AM")
        },
        "unpublishRule": this.cadence.value == 'INSTANT' || this.postType?.value === 'STANDARD' ? null : {
          "daysOfWeek": (
            this.cadence.value == 'WEEKLY' ? this.eventDatesEndWeekly.value : null
          ),
          "daysOfMonth": (
            this.cadence.value == 'MONTHLY' ? [this.eventDatesEndMonthly?.value] : null
          ),
          "time": this.eventTimeEnd?.value ? this.parseTime(this.eventTimeEnd.value) : this.parseTime("11:59 PM")
        },
        "matchEventDates": this.matchScheduleDate?.value
      }
    };
  }

  completeSteeper(isDraft = false): void {
    this.isLoading = true;
    this.dataIsBeingVerified = true;
    this.isCorrectData = false;
    this.fieldsWithError = [];
    const data = this.buildServiceData(isDraft);

    if(this.isEdit) {
      this.editPost(data);
    } else {
      this.createPost(data);
    }
  }

  createPost(data):void {
    this._postS.createPost(data).subscribe(
      res => {
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.drawer.toggle();
        this.closeSlider.emit(true);
      },
      err => {
        this.handleApiError(err?.error?.errors);
      }
    )
  }

  editPost(data):void {
    const postId = this.postToEdit?._id;

    this._postS.editPost(data, postId).subscribe(
      res => {
        this.isLoading = false;
        this.dataIsBeingVerified = false;
        this.drawer.toggle();
        this.closeSlider.emit(true);
      },
      err => {
        this.handleApiError(err?.error?.errors);
      }
    )
  }

  handleApiError(errors) {
    errors.forEach(
      el => {
        if(el?.parameter) {
          const error = el?.displayable_message?.split('. ')[1];
          const field = el.parameter?.split('->');
          let lastPart = field?.[field?.length - 1];
          lastPart = lastPart.replaceAll('occurrence', 'occurence');
          lastPart = lastPart?.replace(/([A-Z])/g, ' $1')?.replace(/_/g, ' ');
          this.fieldsWithError.push({field: lastPart, error: error})
        }
      }
    );
    this.isLoading = false;
    this.dataIsBeingVerified = false;
    this.isCorrectData = true;
  }

  selectedLocations($event): void {
    this.accountsSelected = $event;
  }

  addActionUrl(placeHolder) {
    this.actionUrl.setValue(placeHolder?.value);
  }

  handleRef($event: AngularFireStorageReference): void {
    this.firestorRef = $event;
  }

  onStepChange(event: number): void {
    this.step = event;

    if(this.step === 1) {
      this.addValidationsStepTwo();
      this.buildLocationsNames();
      this.getActionLinks();
    }

    if(this.step === 2) {
      this.addFormFieldsStepThree();
    }

    if(this.step === 3) {
      this.buildDataForLastStep();
      //this.getSchedulePreview();
    }
  }

  backStep(event: number): void {
    this.step = event;
    this.removeAllValidations();

    if(event === 1) {
      this.updateValueAndValidity();
    }
  }

  getActionType(value): string {
    return this.actionOptions.find(el => el?.value === value)?.displayName;
  }

  buildLocationsNames(): void {
    this.locationsSelected = [];
    this.accountsSelected.forEach(
      acc => {
        acc.locations.map(loc => { 
          this.locationsSelected.push(
            {
              'accountName': loc.accountName,
              'locationName': loc.locationName,
              'address': loc.address,
              'serviceArea': loc.serviceArea
            }
          )
        })
      } 
    );
  }

  buildDataForLastStep(): void {
    this.dataForLastStep = {};

    switch(this.cadence?.value) {
      case 'INSTANT':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: 'Does Not Repeat',
          timeOfPublish: this.publishTime?.value ? this.publishTime?.value : null,
          schedulePublish: !this.postStartDate?.value ? 'Now' : this.getServiceDate(this.postStartDate?.value, false),
          scheduledDetele: this.getServiceDate(this.deleteDate?.value, false),
          timeOfDetele: this.deleteTime?.value ? this.deleteTime?.value : null,
          dailyDate: null,
          startDate: this.postType?.value === 'STANDARD' ? null : this.getServiceDate(this.instantEventDatesStart?.value, false),
          endDate: this.postType?.value === 'STANDARD' ? null : this.getServiceDate(this.instantEventDatesEnd?.value, false),
          startTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          endsOn: null,
          firstPublish: null,
          timeOfUnpublish: null,
          repeatsOn: null
        };
        break;
      case 'DAILY':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          timeOfPublish: (
            !this.publishTime?.value ? null : 
            this.postType?.value !== 'STANDARD' ?
            `${this.publishTime.value} until ${this.getEndDate('DAILY')}`:
            this.publishTime.value
          ),
          repeatsOn: this._postS.getDaysUnified(this.publishDay.value),
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
          ),
          firstPublish: this.firstPublishDate?.value ? this.getServiceDate(this.firstPublishDate?.value, false) : 'Wait for the next occurence',
          dailyDate: `Daily on ${this._postS.getDaysUnified(this.publishDay.value)}`,
          startDate: null,
          endDate: null,
          startTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null
        };
        break;
      case 'WEEKLY':
        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          repeatsOn: (
            this.postType?.value !== 'STANDARD' ? 
            `${this.nameOfDaysOptions.find(el => el.value === this.repeatsOnWeekly.value)?.displayName} at ${this.publishTime?.value} until <br> ${this.getEndDate('WEEKLY')} at ${this.getEndTime('WEEKLY')}` : 
            `${this.nameOfDaysOptions.find(el => el.value === this.repeatsOnWeekly.value)?.displayName} at ${this.publishTime?.value}`
          ),
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
            ),
          firstPublish: this.firstPublishDate?.value ? this.getServiceDate(this.firstPublishDate?.value, false) : 'Wait for the next occurence',
          dailyDate: null,
          startDate: this.postType?.value === 'STANDARD' ? null : this.nameOfDaysOptions.find(el => el.value === this.eventDatesStartWeekly.value).displayName,
          endDate: this.postType?.value === 'STANDARD' ? null : this.nameOfDaysOptions.find(el => el.value === this.eventDatesEndWeekly.value).displayName,
          startTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null,
        };
        break;
      case 'MONTHLY':
        const repeatsOn = (
          this.repeatsOnDay?.value === -1 ?
          'last day' : 
          this._postS.getOrdinalNumbers(this.repeatsOnDay?.value)
        );

        this.dataForLastStep = {
          postType: this.postTypeLabel,
          cadence: this.cadence?.value,
          repeatsOn: (
            this.postType?.value !== 'STANDARD' ? 
            `${repeatsOn} of the month at ${this.publishTime?.value} until <br> ${this.getEndDate('MONTHLY')} of the month at ${this.getEndTime('MONTHLY')}` : 
            `${repeatsOn} of the month at ${this.publishTime?.value}`
          ),
          endsOn: (
            this.endsOn.value === 'never' ? 'Never' :
            this.endsOn.value === 'custom_date' ? this.getServiceDate(this.endsOnCustomDate?.value, false) :
            `After ${this.endsOnAfterOcurrences?.value} Occurences`
            ),
          firstPublish: this.firstPublishDate?.value ? this.getServiceDate(this.firstPublishDate?.value, false) : 'Wait for the next occurence',
          dailyDate: `Monthly on the ${repeatsOn} of the month`,
          startDate: (
            this.postType?.value === 'STANDARD' ? null : 
             `${this.eventDatesStartMonthly?.value === -1 ? 'Last day' : this._postS.getOrdinalNumbers(this.eventDatesStartMonthly?.value)} of the month`
          ),
          endDate: (
            this.postType?.value === 'STANDARD' ? null : 
            `${this.eventDatesEndMonthly?.value === -1 ? 'Last day' : this._postS.getOrdinalNumbers(this.eventDatesEndMonthly?.value)} of the month`
          ),
          startTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeStart?.value,
          endTime: this.postType?.value === 'STANDARD' ? null : this.eventTimeEnd?.value,
          couponCode: this.postType?.value !== 'OFFER' ? null : this.form.controls.couponCode?.value,
          linkToRedeem: this.postType?.value !== 'OFFER' ? null : this.form.controls.linkToRedeem?.value,
          termsAndCondition: this.postType?.value !== 'OFFER' ? null : this.form.controls.termsAndCondition?.value,
          schedulePublish: null,
          scheduledDetele: null,
        };
        break;
    }
  }

  openDelete(item: IMediaUrl): void {
    this._modalService.openAlertModal(
      'Confirmation',
      `Are you sure you want to delete this image? This action cannot be undone.`,
      AlertType.ERROR,
      null,
      'Delete',
      (response) => {
        if (response) {
          this._storageImgService.removeFileFromArray(this.mediaUrlArray, item);
          this._storageImgService.notifyFileDeleted();
        }
      },
      'red-alert')
  }

  getSchedulePreview() {
    this.isLoading = true;
    const data = this.buildServiceData(); 
    this.schedulePreviewData = [];

    this._postS.getSchedulePreview(data).subscribe(
      res => {
        this.schedulePreviewData = res?.data;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  changePreview(file: IMediaUrl): void {
    this.selectedUrl = file.url;
  }

  // custom functions for custom swiper
  
  customSwiperPrev(): void {
    if (this.selectedFileIndex === 0) {
      return;
    }
    this.selectedFileIndex--
  }
  
  customSwiperNext(): void {
    if (this.mediaUrlArray.length - 1 === this.selectedFileIndex) {
      return;
    }
    this.selectedFileIndex++;
  }

  // custom swiper

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.isSliderOpened) {
      this.isEdit = changes?.postToEdit && Object.keys(changes.postToEdit.currentValue).length ? true : false;
      // const content = document.getElementsByClassName('content');
      // if (content.length > 0) {
      //   content[0]['className'] += ' pr--0';
      // }
      
      if(!this.isSliderOpened) {
        this.destroyData();
      } else {
        this.initData();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyData();
    super.ngOnDestroy();
  }
  
  destroyData(): void {
    this.accountsSelected = [];
    this.locationsSelected = [];
    this.actionsUrl = [];
    this.dataForLastStep = {};
    this.step = 0;
    this.isEdit = false;
    this.isDraft = false;
    this.postToEdit = null;
    this.isCorrectData = false;
    this.fieldsWithError = [];
    this._storageImgService.reset();
    this._subscriptions.forEach((sub) => sub.unsubscribe());
    this.closeSlider.emit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filtered = this.hoursList.filter(option => option.toLowerCase().includes(filterValue));
    return filtered;
  }

  private _maxCurrentTimeValidation() {
    return (control: FormControl): ValidationErrors | null => {
      if (!control?.value) {
        return null
      } else  {
        const currentTime = moment();
        const inputTime = moment(control.value, 'hh:mm A', true);
        if (!inputTime.isValid()) {
          return { invalidTime: true };
        }
        return inputTime.isBefore(currentTime) ? { maxTime: true } : null;
      }
    };
  }

  private _validateStartBeforeEnd(startControlName: string, endControlName: string) {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const start = this.form.get(startControlName)?.value;
      const end = this.form.get(endControlName)?.value;

      if (!start || !end) {
        return null;
      }
  
      if (start && end && moment(start).isAfter(moment(end))) {
        return { startAfterEnd: true };
      }
      return null;
    };
  }

  private _validateEndTimeSameDay(startTimeControl: string,  endTimeControl: string) {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const startDateControl = ( 
        this.cadence?.value === 'INSTANT' && this.step === 2 ? 'postStartDate' :
        this.cadence?.value === 'INSTANT' ? 'instantEventDatesStart' : 
        this.cadence?.value === 'WEEKLY' ? 'eventDatesStartWeekly' : 
        this.cadence?.value === 'MONTHLY' ? 'eventDatesStartMonthly' :
        null
      )
      const endDateControl = (
        this.cadence?.value === 'INSTANT' && this.step === 2 ? 'deleteDate' :
        this.cadence?.value === 'INSTANT' ? 'instantEventDatesEnd' :
        this.cadence?.value === 'WEEKLY' ? 'eventDatesEndWeekly' : 
        this.cadence?.value === 'MONTHLY' ? 'eventDatesEndMonthly' :
        null
      );

      const startDate = this.cadence?.value === 'DAILY' ? moment() : this.form.get(startDateControl)?.value;
      const startTime = this.form.get(startTimeControl)?.value ? moment(this.form.get(startTimeControl).value, 'hh:mm A') : null;

      const endDate = this.cadence?.value === 'DAILY' ? moment() : this.form.get(endDateControl)?.value;
      const endTime = this.form.get(endTimeControl)?.value ? moment(this.form.get(endTimeControl).value, 'hh:mm A') : null;
  
      if (!startTime || !endTime) {
        return null;
      }

      const isSameDay = (
        this.cadence?.value === 'INSTANT' ? moment(startDate).isSame(moment(endDate), 'day') : 
        typeof(startDate) === 'object' && typeof(endDate) === 'object' ? startDate[0] === endDate[0] :startDate === endDate
      );
  
      if (isSameDay) {
        if ((startTime).isSameOrAfter(endTime)) {
          return  {endTimeBeforeStartTime: true} ;
        }
      }

      return null;

    }
  }

  getError(control, displayName, fieldToCompare): string {
    const errors = Object.keys(control?.errors);

    return (
      errors.includes('required') ? `${displayName} is required` :
      errors.includes('startAfterEnd') ? `${displayName} should be later than the ${fieldToCompare}` :
      errors.includes('endTimeBeforeStartTime') ? `${displayName} should be later than the ${fieldToCompare}` :
      null
    )
  }

  getMinDate() {
    return new Date();
  }
  
  getDisabledFieldTooltip(): string | null {
    const message = this.cadence.value === 'INSTANT' ?
    `You can’t edit the schedule for this post. If you’d like to change the schedule, you need to create a new post with the desired schedule change` :
    `You can’t edit the schedule for a recurring post. If you’d like to change the schedule, you need to create a new post with the desired schedule change`;

    return (
      this.isEdit && !this.isDraft ?
      message :
      null
    )
  }

  get getPublishingSchedule(): string {
    let txt = `Posts will be published ${(this.cadence.value).toLowerCase()} on `;
    let startDate, startTime;

    switch(this.cadence.value) {
      case 'DAILY':
        startDate = this.publishTime?.value ? this.publishTime.value : '12:00 AM';
        txt += `<strong>${this.daysUnified}</strong> at <strong>${startDate}</strong>, until <strong>${this.getEndDate()}</strong>`;

        break;
      case 'WEEKLY':
        startDate = this.nameOfDaysOptions.find(el => el.value === this.eventDatesStartWeekly?.value)?.displayName || '';
        startTime = this.publishTime?.value ? this.publishTime?.value : '12:00 AM';

        txt += `<strong>${startDate}</strong> at <strong>${startTime}</strong>, until <strong>${this.getEndDate()}</strong> at <strong>${this.getEndTime()}</strong>`;
        break;
      case 'MONTHLY':
        startDate = this.eventDatesStartMonthly?.value > -1 ? this._postS.getOrdinalNumbers(this.eventDatesStartMonthly.value) : 'last day';
        startTime = this.publishTime?.value ? this.publishTime?.value : '12:00 AM';

        txt += `<strong>${startDate} of the month</strong> at <strong>${startTime}</strong>, until <strong>${this.getEndDate()} of the month</strong> at <strong>${this.getEndTime()}</strong>`;
        break;
    }

    return txt;
  }

  getEndDate(frequency?): string {
    const cadence = frequency ? frequency : this.cadence?.value;
    let endDate;
    switch(cadence) {
      case 'DAILY':
        endDate = this.eventTimeEnd?.value ? this.eventTimeEnd.value : '11:59 PM';
        break;
      case 'WEEKLY':
        endDate = this.nameOfDaysOptions.find(el => el.value === this.eventDatesEndWeekly?.value)?.displayName || '';
        break;
      case 'MONTHLY':
        endDate = this.eventDatesEndMonthly?.value > -1 ? this._postS.getOrdinalNumbers(this.eventDatesEndMonthly.value) : 'last day';
        break;
    }
    return endDate;
  }

  getEndTime(frequency?): string {
    const cadence = frequency ? frequency : this.cadence?.value;
    let endTime;
    switch(cadence) {
      case 'WEEKLY':
        endTime = this.eventTimeEnd?.value ? this.eventTimeEnd?.value : '11:59 PM';
        break;
      case 'MONTHLY':
        endTime = this.eventTimeEnd?.value ? this.eventTimeEnd?.value : '11:59 PM';
        break;
    } 

    return endTime;
  }

  get daysUnified(): string {
    return this._postS.getDaysUnified(this.publishDay.value);
  }

  get formattedSummary(): string {
    const summary = this.summary?.value?.replaceAll(/\n/g, '<br>');
    
    return summary;
  }

  getLowercase(value): string {
    return value.toLowerCase();
  }

  getPlaceholder(field): string {
    return field.value ? 'Time' : 'Time (Optional)'
  }

  get postTypeLabel() {
    return this.postType?.value === 'STANDARD' ? 'Update' : this.postType.value;
  }

  get completeStep1(): boolean {
    let isComplete = false
    if(this.isBulk) {
      let totalLocations = 0;
      this.accountsSelected?.forEach(account => {
        totalLocations += account?.locations?.length;
      });
      isComplete = this.accountsSelected?.length > 0 && totalLocations >= 2
    } else {
      isComplete = this.accountsSelected?.length > 0;
    }
    
    return isComplete;
  }

  get completeStep2(): boolean {
    return this.form?.valid;
  }

  get completeStep3(): boolean {
    return this.form?.valid;
  }

  get postType(): any {
    return this.form?.controls?.postType;
  }

  get cadence(): any {
    return this.form?.controls?.cadence;
  }

  get schedulePublish(): any {
    return this.form?.controls?.schedulePublish;
  }

  get postStartDate(): any {
    return this.form.controls?.postStartDate;
  }

  get deleteDate(): any {
    return this.form?.controls?.deleteDate;
  }

  get publishTime(): any {
    return this.form?.controls?.publishTime;
  }

  get deleteTime(): any {
    return this.form?.controls?.deleteTime;
  }

  get publishDay(): any {
    return this.form?.controls?.publishDay;
  }

  get unifiedPublishDayValues(): string {
    return this._postS.getDaysUnified(this.publishDay.value);
  }

  get endsOn(): any {
    return this.form?.controls?.endsOn;
  }

  get endsOnCustomDate(): any {
    return this.form?.controls?.endsOnCustomDate;
  }

  get endsOnAfterOcurrences(): any {
    return this.form?.controls?.endsOnAfterOcurrences;
  }
  
  get repeatsOnMonthly(): any {
    return this.form?.controls?.repeatsOnMonthly;
  }
  
  get repeatsOnWeekly(): any {
    return this.form?.controls?.repeatsOnWeekly;
  }

  get repeatsOnDay(): any {
    return this.form?.controls?.repeatsOnDay;
  }

  get firstPublish(): any {
    return this.form?.controls?.firstPublish;
  }

  get firstPublishDate(): any {
    return this.form?.controls?.firstPublishDate;
  }

  get title(): any {
    return this.form?.controls?.title;
  }

  get summary(): any {
    return this.form?.controls?.summary;
  }

  get eventDatesStartWeekly(): any {
    return this.form?.controls?.eventDatesStartWeekly;
  }

  get eventDatesEndWeekly(): any {
    return this.form?.controls?.eventDatesEndWeekly;
  }

  get eventDatesStartMonthly(): any {
    return this.form?.controls?.eventDatesStartMonthly;
  }

  get eventDatesEndMonthly(): any {
    return this.form?.controls?.eventDatesEndMonthly;
  }

  get instantEventDatesStart(): any {
    return this.form?.controls?.instantEventDatesStart;
  }

  get instantEventDatesEnd(): any {
    return this.form?.controls?.instantEventDatesEnd;
  }

  get weeklyEventsDates(): any {
    return this.form?.controls?.weeklyEventsDates;
  }

  get matchScheduleDate(): any {
    return this.form?.controls?.matchScheduleDate;
  }

  get eventTimeStart(): any {
    return this.form?.controls?.eventTimeStart;
  }

  get actionType(): any {
    return this.form?.controls?.actionType;
  }

  get actionUrl(): any {
    return this.form?.controls?.actionUrl;
  }

  get eventTimeEnd(): any {
    return this.form?.controls?.eventTimeEnd;
  }

  get disabledNextStep(): boolean {
    return !(
      this.step === 0 ? this.completeStep1 : 
      this.step === 1 ? this.completeStep2 : 
      this.step === 2 ? this.completeStep3 : true
    );
  }
  
}